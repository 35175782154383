<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement bg-white innerPage-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12"> 
                        <div class="breadCrum d-flex align-items-center justify-content-between">
                            <div class="bdPage">
                                <h2>Technical Analysis</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/">Home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Technical Analysis</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="rightSearch d-flex align-items-center">
                                <a href="javascript:void(0)" @click="() => movePage('All')" class="green_btn d-flex align-items-center me-3">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                                <!-- <form class="searchBar hideMobile">
                                    <div class="form-group position-relative inputIcon mb-0">
                                        <input type="text" class="form-control" placeholder="Search.." name="" /> 
                                        <a class="searchButton" href="#"><vue-feather type="search"></vue-feather> </a>
                                    </div>
                                </form> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white mainNewsBlock imageFluid marketInsiteBlock pb-md-5">
            <div class="container">
                <div class="row" v-if="store.financialContent.totalRecords && store.financialContent.totalRecords > 0">
                    <div class="col-12 col-xl-6 mb-4 mb-xl-0" v-for="(item, key) in store.financialContent.records.slice(0, 1)" :key="key">
                        <div class="card-body topNews p-0" :id="'insight'+item._id">
                            <img :src="item.image_url" alt="News" title="News Image" class="img-fluid related-3">
                            <div class="content">
                                <ul class="d-flex align-items-center justify-content-between my-2">
                                    <li class="me-4"><a class="lighttext" @click="id = item._id;calinsightsDetail()" href="javascript:void(0)">{{item.source}}</a></li>
                                    <li class="d-flex align-items-center line-1 lighttext"><span><vue-feather class="me-1" size="14px" type="clock"></vue-feather></span>{{formatNow(item.created,'now',0)}}</li>
                                </ul>
                                <h3 class="pt-1">{{(item.source != 'FXS') ? item.metadata.header : item.title}}</h3>
                                <p v-html="item.text.substring(0,100)+'...'"></p>
                                <a href="javascript:void(0)" class="green medium" @click="() => goToDetailPage(item)">Read More</a>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 mb-4 mb-lg-0">
                        <div class="card-body d-flex align-items-start p-0 mb-4 parentNews" v-for="(item, key) in store.financialContent.records.slice(1, 4)" :key="key">
                            <span class="flex-shrink-0 mb-2 mb-md-0"><img class="img-fluid h-200" :src="item.image_url" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3" :id="'insight'+item._id">
                                <h6 class="medium">{{(item.source != 'FXS') ? item.metadata.header : item.title}}</h6>
                                <p class="mb-2 f-16" v-html="item.text.substring(0,100)+'...'"></p>
                                <a href="javascript:void(0)" class="green medium" @click="() => goToDetailPage(item)">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white marketInsiteBlock  space-footer">
            <div class="container">
                <div class="row mb-md-5" v-if="store.treandingInsight.totalRecords && store.treandingInsight.totalRecords > 0">
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <h3 class="mb-0">What's Trending</h3>
                            <a href="javascript:void(0)" @click="() => movePage('trending')" class="linkBtn d-flex align-items-center">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                        </div>
                    </div>
                    <carousel :settings="settings" :breakpoints="breakpoints"  v-if="store.treandingInsight.totalRecords && store.treandingInsight.totalRecords  > 0">
                         <slide v-for="item,key in store.treandingInsight.records.slice(0,10)" :key="key">
                            <div class="card-news" :id="'insight'+item._id">
                                <div class="position-relative">
                                    <!-- <div class="col-12 col-xl-3 col-md-6 mb-4 mb-xl-0" :id="'insight'+item._id"> -->
                                        <img class="imgImi mb-2" :src="item.image_url">
                                        <h6 v-if="item.metadata && item.metadata.header">{{item.metadata.header.slice(0, 50)}}...</h6>
                                        <h6 v-else>{{item.title.slice(0, 50)}}...</h6>
                                        <p class="line-clamp medium" v-html="item.text.substring(0,100)+'...'"></p>
                                        <a href="javascript:void(0)" class="green medium" @click="() => goToDetailPage(item)">Read More</a>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </slide>
                        <template #addons>
                            <Navigation />
                            <!-- <pagination /> -->
                        </template>
                    </carousel>
                </div>
                <div class="row mb-md-5" v-if="store.treandingInsight.totalRecords && store.treandingInsight.totalRecords > 0">
                    <div class="col-12 col-lg-5 mb-4 mb-lg-0">
                        <div class="card-body topNews p-0" v-for="item,key in store.treandingInsight.records.slice(10,12)">
                            <div class="content border-bottom trade pb-3 mb-3" :id="'insight'+item._id">
                                <ul class="d-flex align-items-center justify-content-between my-2">
                                    <li class="me-4"><a class="lighttext" href="javascript:void(0)">{{item.source}} </a></li>
                                    <li class="d-flex align-items-center line-1 lighttext"><span><vue-feather class="me-1" size="14px" type="clock"></vue-feather></span>{{formatNow(item.created,'now',0)}}</li>
                                </ul>
                                <h3 class="pt-1" v-if="item.metadata && item.metadata.header">{{(item.source != 'FXS') ? item.metadata.header : item.title}}</h3>
                                <p v-html="item.text.substring(0,100)+'...'"></p>
                                <a href="javascript:void(0)" class="green medium" @click="() => goToDetailPage(item)">Read More</a>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-7">
                        <div class="row">
                            <div class="col-12 col-lg-6 mb-4 mb-lg-0" v-for="item,key in store.treandingInsight.records.slice(12,14)">  
                                <img class="img-fluid" :src="item.image_url">
                                <ul class="d-flex align-items-center justify-content-between my-2" :id="'insight'+item._id">
                                    <li class="me-4"><a class="lighttext" href="javascript:void(0)">{{item.source}} </a></li>
                                    <li class="d-flex align-items-center line-1 lighttext"><span><vue-feather class="me-1" size="14px" type="clock"></vue-feather></span> {{formatNow(item.created,'now',0)}}</li>
                                </ul>
                                <h6 v-if="item.metadata && item.metadata.header">{{(item.source != 'FXS') ? item.metadata.header : item.title}}</h6>
                                <p v-html="item.text.substring(0,100)+'...'"></p>
                                <a href="javascript:void(0)" class="green medium" @click="() => goToDetailPage(item)">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="store.ReadfinancialContent.totalRecords && store.ReadfinancialContent.totalRecords > 0">
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <h3 class="mb-0">Most Read</h3>
                            <a href="javascript:void(0)" @click="() => movePage('All')" class="linkBtn d-flex align-items-center">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                        </div>
                    </div>
                    <div class="col-12 col-xl-3 col-md-6 mb-4 mb-xl-0" v-for="(item, key) in store.ReadfinancialContent.records.slice(0, 4)" :key="key" :id="'insight'+item._id">
                        <img class="img-fluid minCal mb-2" :src="item.image_url">
                        <h6 v-if="item.metadata && item.metadata.header">{{(item.source != 'FXS') ? item.metadata.header.slice(0, 50) : item.title}}...</h6>
                        <p v-html="item.text.substring(0,100)+'...'"></p>
                        <a href="javascript:void(0)" class="green medium" @click="() => goToDetailPage(item)">Read More</a>
                    </div>
                </div>
            </div>
        </section>
    <!-- Open Modal -->
        <!-- <div class="modal fade ideaModal" v-if="id != ''" :class="(id != '') ? 'show' : ''">
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="row modal-content g-0 h-100">
                <div class="col-12 col-lg-6 ideaChart">
                    <img class="d-block w-100 h-100" :src="id_data(id).image_url" alt="News" title="News Image">
                </div>  
                <div class="col-12 col-lg-6 d-flex flex-column ideaChart border-start">
                    <div class="modal-body p-4 pt-0 border-0">
                        <div class="d-flex align-items-center justify-content-between modalHeader mb-3 py-3">
                            <ul class="d-flex align-items-center mb-0">
                                <li><a href="javascript:void(0)" class="d-flex me-3"><vue-feather size="23px" type="share-2"></vue-feather></a></li>
                                <li><a href="javascript:void(0)" class="d-flex "><vue-feather size="23px" type="printer"></vue-feather></a></li>
                            </ul>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="id = ''"></button>
                        </div>
                        <ul class="datTime d-flex align-items-center mb-0">
                            <li class="me-4"><a class="green" href="javascript:void(0)">javascript:void(0) </a></li>
                            <li>{{formatNow(id_data(id).created,'now',0)}}</li>
                        </ul>
                        <h2 class="medium my-4 py-2">{{(id_data(id).source != 'FXS') ? id_data(id).metadata.header : id_data(id).title}}</h2>
                        <p v-html="id_data(id).text"></p>
                    </div>
                    <div class="modalFooter p-0 border-0">
                        <div class="prevNextNews border-top trade d-flex align-items-center justify-content-between">
                            <a href="javascript:void(0)" class="border-end prev d-flex align-items-center" @click="next_prev(-1)">
                                <i class="me-2 fa fa-caret-left" aria-hidden="true"></i>Previous
                            </a>
                            <a href="javascript:void(0)" class="border-start prev d-flex align-items-center" @click="next_prev(1)">
                                Next <i class="ms-2 fa fa-caret-right" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div> -->
    <!-- End -->
    </div>
</template>

<script>
import { useMainStore } from "@/store";
import bannerslider from "../../components/shared/banner_slider";
import moment from 'moment';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation,  } from 'vue3-carousel';
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            id:'',
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                767: {
                  itemsToShow: 3,
                  snapAlign: 'center',
                },
                1024: {
                  itemsToShow: 3,
                  snapAlign: 'start',
                },
                1199: {
                  itemsToShow: 5,
                  snapAlign: 'start',
                },
            },

        }
    },
    components: {
        bannerSlider:bannerslider,
        Carousel,
        Slide,
        Navigation,
    }, 
    watch : {
        // 'id'(val){
        //     const el = document.body;
        //     if(val){
        //         if (el.classList.contains('position-fixed')) {
        //           el.classList.remove('position-fixed');
        //         } else {
        //           el.classList.add('position-fixed');
        //         }
        //     }else{
        //         el.classList.remove('position-fixed');
        //     }
        // }
    },
    methods:{
        movePage(type){
            this.$router.push({
                path: '/all-market-insights',
                query: {'type' :type},
              });
        },
        toDivElement(id){
            try{
                setTimeout(function(){
                    var elmnt = document.getElementById(id);
                    if(elmnt){
                        elmnt.scrollIntoView({behavior: "smooth"});
                        document.getElementById(id).focus();
                        //var top = $(window).scrollTop()
                        // console.log(id,'top',top)
                        // $(window).scrollTop(top-80)
                    }
                }, 500);
            }catch(e){}
        },
        goToDetailPage(item){
            let title = ''
            if(item.source != 'FXS'){
                title = item.metadata.header
            }else{
                title = item.title
            }
            if(title.includes('-')){
                title = title.replace(/-+/g, '')
            }else{
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if(titl.includes('--')){
                    title = titl.replace(/--+/g, '-')
                }else{
                    if(item.source != 'FXS'){
                        title = item.metadata.header
                    }else{
                         title = item.title
                    }
                }
            }
            this.$router.push({
                name: 'insight_detail',
                params : {'id' : item._id,'title':title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'),'page' : 1}
            });
        },
        formatNow(date,format,offset){
            if(!date) return 'N/A'
            let d = moment(date, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
        calinsightsDetail(){
            let form ={
                '_id' : this.id
            }
            this.store
          .callFinancialContentDetail(form, false)
          .then((response) => {
          })
          .catch();
        },
        id_data(id){
            return this.store.allMarketInsights.find(val=>val._id == id) || {}
        },
        id_index(id){
            return this.store.allMarketInsights.findIndex(val=>val._id == id)
        },
       next_prev(to){
            //console.log('return',this.id_index(this.id)+to)
            if((this.id_index(this.id)+to) < 0){
                this.id = this.store.allMarketInsights[this.store.allMarketInsights.length - 1]._id
            }else if(this.store.allMarketInsights.length == this.id_index(this.id)+to){
                this.id = this.store.allMarketInsights[0]._id
            }else{
                this.id = this.store.allMarketInsights[this.id_index(this.id)+to]._id
            }
            //console.log('4',this.store.allMarketInsights[4]._id)
        },

    }, 
    mounted(){
        if(this.$router.options.history.state.forward && this.$router.options.history.state.forward.split('/')[1] == 'insight-detail'){
            let data = this.$router.options.history.state.forward.split('/');
            this.toDivElement('insight'+data[2])
            this.$router.options.history.state.forward = ""
            this.page = 1
        }
    },
    created() {
    let formData = {
      page: 1,
      perPage: 15,
      type : 'most_read'
    };
    this.store
      .callFinancialContent(formData, false)
      .then(() => {})
      .catch((err) => console.log(err.message));

        let json = {
          page: 1,
          perPage: 15,
        };
        this.store
          .callFinancialContent(json, false)
          .then(() => {})
          .catch((err) => console.log(err.message));

        this.store
          .callTreandingFinancialContent(json, false)
          .then(() => {})
          .catch((err) => console.log(err.message));
        
    }, 
}
</script>
